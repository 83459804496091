<template>
  <div
    class="vat-cell-container"
    :style="`width: ${column.width}px;`"
    :class="{ 'less-opacity': notDefinedVat }"
  >
    <div
      class="text-wrapper"
      :style="`max-width: ${column.width}px;`"
      :class="{ '--black': column.data !== 'full' }"
    >
      <template v-if="column.data === 'rate'"> {{ vatRate }} </template>
      <template v-if="column.data === 'origin'"> {{ vatOrigin }} </template>
      <template v-if="column.data === 'full'"> {{ vatFullText }} </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { VatType } from "@silexpert/core";
import { JSONPath } from "jsonpath-plus";
import type { ConfigColumn } from "~/@types/localTypes/table";
const serverInfoStore = useServerInfoStore();

const props = defineProps<{
  item: any;
  column: ConfigColumn;
}>();

const data = computed<VatType | number | null>(() => {
  const path = props.column.path;
  if (isNotDefined(path)) return 0;

  const value = JSONPath({ path, json: props.item })[0] ?? null;
  return value;
});

const isAVatId = computed(() => {
  return typeof data.value === "number";
});

const matchingVat = computed(() => {
  if (isAVatId.value) {
    const vats = serverInfoStore.vats ?? [];
    return vats.find((vat: { idTva: number }) => vat.idTva === data.value) ?? null;
  } else {
    return data.value;
  }
}) as ComputedRef<VatType | null>;

const vatRate = computed(() => {
  return `${matchingVat?.value?.value}%`;
});

const vatOrigin = computed(() => {
  if (matchingVat?.value?.isVatImportation) {
    return "HUE";
  } else if (matchingVat?.value?.isVatIntracom) {
    return "UE";
  } else {
    return "FR";
  }
});

const vatFullText = computed(() => {
  return matchingVat.value?.name ?? "Choisir la TVA";
});

const notDefinedVat = computed(() => {
  return !isDefined(data.value);
});
</script>

<style lang="scss" scoped>
.vat-cell-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  font-size: 16px;
  line-height: 20px;

  .text-wrapper {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: $uds-neutral-800;

    &.--black {
      color: $uds-neutral-900;
    }
  }
}
.less-opacity {
  opacity: 0.3;
}
</style>
