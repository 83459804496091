<template>
  <div
    class="text-cell-container"
    :class="{ '--start': flex === 'start' }"
    :style="`width: ${column.width}px;`"
  >
    <uds-tooltip :tooltip-text="`${text}`">
      <div
        class="text-wrapper"
        :class="{ 'less-opacity': isLessOpacity }"
        :style="`max-width: ${column.width}px;`"
      >
        {{ text }}
      </div>
    </uds-tooltip>
  </div>
</template>

<script setup lang="ts">
import { JSONPath } from "jsonpath-plus";
import type { ConfigColumn } from "~/@types/localTypes/table";
const props = defineProps<{
  item: any;
  column: ConfigColumn;
}>();

const isLessOpacity = ref(false);

const flex = computed(() => props.column.flex ?? null);

const text = computed(() => {
  const path = props.column.path;
  if (isNotDefined(path)) return "";

  const value = JSONPath({ path, json: props.item })[0];

  if (!isDefined(value) && isDefined(props.column.emptyText)) {
    // eslint-disable-next-line vue/no-side-effects-in-computed-properties
    isLessOpacity.value = true;
  } else {
    // eslint-disable-next-line vue/no-side-effects-in-computed-properties
    isLessOpacity.value = false;
  }
  return value ?? props.column.emptyText ?? "";
});
</script>

<style lang="scss" scoped>
.text-cell-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  font-size: 16px;
  line-height: 20px;
  color: $uds-neutral-900;

  &.--start {
    justify-content: flex-start;
  }

  .text-wrapper {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    &.less-opacity {
      color: $uds-neutral-500;
    }
  }
}
</style>
