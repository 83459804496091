<template>
  <div class="date-cell-container">
    <div class="text-wrapper">
      {{ dateText }}
    </div>
  </div>
</template>

<script setup lang="ts">
import { JSONPath } from "jsonpath-plus";
import type { ConfigColumn } from "~/@types/localTypes/table";

const props = defineProps<{
  item: any;
  column: ConfigColumn;
}>();

const dateText = computed(() => {
  const { formatDate } = useDateFormat();

  const path = props.column.path;
  const dateFormat = props.column.dateFormat ?? "DD-MM-YYYY";

  if (isNotDefined(path)) return "";

  const value = JSONPath({ path, json: props.item })[0];

  return value ? formatDate(value, dateFormat) : "";
});
</script>

<style lang="scss" scoped>
.date-cell-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 16px;
  line-height: 20px;
  color: $uds-neutral-800;

  .text-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
