<template>
  <div class="amount-cell-container">
    <uds-amount-currencie
      :value="amount"
      :has-symbol="hasSymbol"
      :success="shouldApplyColor && amount > 0"
      :error="useErrorColor && amount < 0"
    />
  </div>
</template>

<script setup lang="ts">
import { JSONPath } from "jsonpath-plus";
import type { ConfigColumn } from "~/@types/localTypes/table";

const props = defineProps<{
  item: any;
  column: ConfigColumn;
}>();

const shouldApplyColor = computed<boolean>(
  () => isDefined(props.column.useColor) && props.column.useColor === true,
);

const useErrorColor = computed(
  () => isDefined(props.column.useErrorColor) && props.column.useErrorColor === true,
);

const hasSymbol = computed(() =>
  isDefined(props.column.hasSymbol) ? props.column.hasSymbol : true,
);

const amount = computed(() => {
  const path = props.column.path;
  if (isNotDefined(path)) return 0;

  const value = JSONPath({ path, json: props.item })[0] ?? 0;

  return value;
});
</script>

<style lang="scss" scoped>
.amount-cell-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  height: 100%;

  padding-right: $uds-spacing-2;
  box-sizing: border-box;
  font-weight: $uds-weight-500;
  font-size: 18px;
  color: $uds-neutral-900;
}
</style>
